<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-3" v-for="(item,index) in dataList" :key="index">
          <div class="box">
            <img :src="item.imgUrl" alt />
            <h3>{{item.title}}</h3>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'coomPromise',
  data() {
    return {
      dataList: [
        {
          title: '运行稳定',
          text: '支持WindowsXP/7/8/10/等多个版本上运行。',
          imgUrl: require('@/assets/images/icon/b1.png')
        },
        {
          title: '安全可靠',
          text: '数据自动备份，加密存储。',
          imgUrl: require('@/assets/images/icon/b2.png')
        },
        {
          title: '简单易用',
          text: '操作简单，使用方便，自动统计。',
          imgUrl: require('@/assets/images/icon/b3.png')
        },
        {
          title: '金牌售后',
          text: '优质的售后服务和专业的技术支持。',
          imgUrl: require('@/assets/images/icon/b3.png')
        }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .box {
    margin: 20px 0 0 0 !important;
    img {
      width: 68px !important;
      height: 68px !important;
    }
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }
}

p {
  margin-bottom: 0;
}
.box {
  margin: 40px 10px;
  text-align: center;
  h3 {
    margin: 10px 0;
    font-size: 24px;
  }
  p {
    font-size: 14px;
    line-height: 28px;
  }
}
</style>