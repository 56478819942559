<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list>
    </div>
    <div class="center">
      <div class="profile" data-aos="fade-up" data-aos-offset="0" data-aos-delay="50" data-aos-duration="800">
        <div class="container">
          <div class="about-title">公司简介</div>
          <div class="row">
            <div class="col-sm-3 col-6">
              <div class="box">
                <div class="num">
                  <p>10</p>
                  <span>年</span>
                </div>
                <p>行业经验</p>
              </div>
            </div>
            <div class="col-sm-3 col-6">
              <div class="box">
                <div class="num">
                  <p>10000</p>
                  <span>+</span>
                </div>
                <p>客户的选择</p>
              </div>
            </div>
            <div class="col-sm-3 col-6">
              <div class="box">
                <div class="num">
                  <p>500</p>
                  <span>+</span>
                </div>
                <p>版本迭代</p>
              </div>
            </div>
            <div class="col-sm-3 col-6">
              <div class="box">
                <div class="num">
                  <p>{{new Date().getFullYear()}}</p>
                </div>
                <p>简单客</p>
              </div>
            </div>
          </div>
          <div>
            <p class="about-p">河北畅麦科技有限公司（简称：畅麦科技）成立于2019年，是一家集软件开发与互联网技术服务为一体的科技企业，提供零售行业的数据智慧化解决方案。目前的主要产品有会员收银系统、往来账务管理系统、维修行业系统等。</p>
            <p
              class="about-p"
            >始终坚持着“简洁·不简单”的理念，畅麦科技不断汲取知识，创新技术，从用户切实需求出发，结合互联网的云技术，为各行业量身研发了一系列系统产品，有效解决企业运营成本高、客户无法量化管理等需求，帮助企业降低管理成本，有效提升销售额及行业内影响力，让老板随时随地掌控经营概况。</p>
            <p class="about-p">畅麦科技客户辐射全国，畅麦科技服务商家10000+，并且还在以更快的速度发展。</p>
            <p class="about-p">旗下“简单客”品牌，服务的商户类别包括：美业养生、休闲沐足、餐饮酒店、手机数码、电脑耗材、各种制造业、培训教育、商场超市、等多个业态。目前，简单客会员营销平台已是国内普遍应用、客户认同率高的管理软件之一。</p>
          </div>
        </div>
      </div>
      <div class="promise" data-aos="fade-up" data-aos-offset="0" data-aos-delay="50" data-aos-duration="800">
        <div class="container">
          <div class="about-title">我们承诺</div>
          <comm-promise />
        </div>
      </div>
      <comm-desc />
    </div>
  </div>
</template>

<script>
import API from '@/api/soft'
import swiperList from '@/components/swiperList'
import commPromise from '@/components/commPromise'
import commDesc from '@/components/commDesc'
export default {
  name: 'AboutIndex',
  components: {
    swiperList,
    commPromise,
    commDesc
  },
  data() {
    return {
      dataList: [],
      swiperList: [
        {
          image: '',
          title: '关于我们',
          subTitle: '专注一件事：用心做产品',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-5.jpg'),
          buttons: []
        }
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.getSoftList()
  },
  methods: {
    getImgSrc(softCode) {
      try {
        return require(`@/assets/images/product/logo_${softCode}.png`)
      } catch {
        return ''
      }
    },
    getSoftList() {
      API.getList({}).then(res => {
        let cates = []
        res.data.map(item => {
          let idx = -1
          cates.some((item1, index1) => {
            if (item1.name == item.cate) {
              idx = index1
              return true
            }
          })

          if (idx < 0) {
            cates.push({ name: item.cate, softs: [] })
            idx = cates.length - 1
          }
          cates[idx].softs.push(item)
        })
        console.log(cates)
        this.dataList = cates
      })
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
@media screen and (max-width: 576px) {
  .swimlane-container {
    h2 {
      font-size: 28px !important;
    }
    p {
      font-size: 22px !important;
    }
  }
  .profile {
    margin: 30px 0;
    .box {
      padding: 10px 0 !important;
      .num {
        p {
          font-size: 32px !important;
        }
        span {
          font-size: 24px !important;
        }
      }
    }
    .about-p {
      font-size: 14px !important;
    }
  }
}

.app-top {
  margin-top: 58px;
}
.profile {
  margin: 30px 0;
  .box {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .num {
      p {
        display: inline;
        font-size: 48px;
      }
      span {
        font-size: 36px;
        vertical-align: top;
      }
    }
    p {
      font-size: 16px;
    }
  }
  .about-p {
    text-indent: 2em;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.promise {
  margin: 30px 0;
}
.about-title {
  font-size: 22px;
  font-weight: bold;
}
</style>